import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { BASE_URL } from '../../api/API';
import { useSelector } from 'react-redux';

const PostTypeGraph = () => {
  const { isDark } = useSelector((state) => state.common);
  const { userId, dateRange } = useSelector((state) => state.analytics);
  const [data, setData] = useState([]);
  const [displayedDataKey, setDisplayedDataKey] = useState('likes');
  const [yAxisDomain, setYAxisDomain] = useState([0, 10]); // Initial domain for Y-axis

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
        const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);

        const response = await axios.get(
          `${BASE_URL}post-analytics/user/${userId}/summary-by-type?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
        );

        // Transform data to match required format
        const transformedData = Object.entries(response.data).map(([key, value]) => ({
          postType: key === 'NONE' ? 'TEXT' : key,
          likes: value.likes,
          comments: value.comments,
          impressions: value.impressions,
          engagements: value.engagements,
        }));

        setData(transformedData);

        // Dynamically update Y-axis domain based on the selected metric
        const selectedMetricValues = transformedData.map((item) => item[displayedDataKey]);
        const minValue = Math.min(...selectedMetricValues);
        const maxValue = Math.max(...selectedMetricValues);
        setYAxisDomain([Math.floor(minValue), Math.ceil(maxValue)]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId, dateRange, displayedDataKey]);

  return (
    <div
      className={`flex flex-col items-start justify-start w-full h-full transition-colors duration-300 ${
        !isDark ? 'text-white' : 'bg-black-50 text-black'
      }`}
    >
      {/* Title and Dropdown Row */}
      <div className="w-full flex items-center justify-between mb-6">
        <h3 className="text-3xl font-bold text-left">Post Type Analysis</h3>
        <select
          onChange={(e) => setDisplayedDataKey(e.target.value)}
          value={displayedDataKey}
          className={`p-2 border rounded text-sm ${
            isDark ? 'bg-white text-black' : 'bg-gray-700 text-white'
          }`}
        >
          <option value="likes">Likes</option>
          <option value="comments">Comments</option>
          <option value="impressions">Impressions</option>
          <option value="engagements">Engagements</option>
        </select>
      </div>

      {/* Bar Chart */}
      <div
        className={`relative w-full max-w-[750px] mx-auto h-[500px] rounded-md overflow-hidden mb-6 ${
          !isDark ? 'bg-[#2D2D2D] text-white' : 'bg-gray-50 text-black'
        }`}
        style={{ padding: '16px' }}
      >
        {data.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p className={`text-lg font-semibold ${isDark ? 'text-gray-600' : 'text-gray-400'}`}>
              No data available to display. Please adjust your filters or try again later.
            </p>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              {/* Gradient Definition */}
              <defs>
                <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8a51ff" />
                  <stop offset="100%" stopColor="#39b2ff" />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="postType"
                axisLine={{ stroke: '#CCC' }}
                tickLine={false}
                tick={{
                  fill: isDark ? '#000' : '#FFF',
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
              />
              <YAxis
                domain={[0, (dataMax) => Math.ceil(dataMax)]} // Ensure integer max value
                axisLine={{ stroke: '#CCC' }}
                tickLine={false}
                tick={{
                  fill: isDark ? '#000' : '#FFF',
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
                tickFormatter={(value) => Math.round(value)} // Format as integers
              />
              <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" stroke="#DDD" />
              <Bar
                dataKey={displayedDataKey}
                radius={[10, 10, 0, 0]}
                fill="url(#barGradient)"
                maxBarSize={80}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default PostTypeGraph;
