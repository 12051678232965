import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostCard from "../../components/PostCard/PostCard";
import "./History.css";
import { getApi } from "../../api/methods";
import InfiniteScroll from "react-infinite-scroll-component";
import { clearPostState } from "../../store/features/post/postSlice";
import PreviewPost from "../../components/PostIdea/PreviewPost/PreviewPost";
import { IMAGE_URL } from "../../api/API";
import { Input } from "../../components/ui/input";
import searchImg from "../../assets/searchImg.svg";
import searchImgWhite from "../../assets/searchImgWhite.svg";
import { apibase } from "../../api/API";

const History = () => {
  const user = useSelector((state) => state.user);
  const { isDark, layoutShift } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTotalLength, setDataTotalLength] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filterItem, setFilterItem] = useState([]);
  const [modalData, setModalData] = useState(null);

  const [url, setUrl] = useState("");
  const post = useSelector((state) => state.post);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Fetch all history posts of the user as per page
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `published/user/${user?.userData?.linkedinId}?page=${page}&pageSize=24`
      );
      const newData = response.data;
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
      setDataTotalLength(response.totalLength);

      if (data.length + newData.length >= response.totalLength) {
        setHasMore(false);
      } else {
        setPage(page + 1);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };


  // Search functionality
  const searchHandler = () => {
    const filterItems = data.filter((item) =>
      item.postContent?.toLowerCase().includes(searchItem.toLowerCase())
    );
    setFilterItem(searchItem ? filterItems : []);
  };

  useEffect(() => {
    searchHandler();
  }, [searchItem, data]);

  useEffect(() => {
    if (user.userData.linkedinId) {
      fetchData();
    }
  }, [user.userData.linkedinId]);

  useEffect(() => {
    if (post.postType === "ARTICLE") {
      setUrl(post.originalUrl);
    }
  }, [post.postType, post.originalUrl]);

  useEffect(() => {
    if (url !== "") {
      fetchMetaData();
    }
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url,
      });
      setModalData(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  return (
    <div
      className={`flex flex-col gap-4 py-12 ${layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"
        }`}
    >
      <div className="flex flex-col gap-4">
        <div className="sm:flex justify-between items-center "
          style={{ paddingLeft: isMobile ? '15%' : '0%' }}
        >
          <h3 className="text-sm sm:text-xl">You are not missing anything</h3>
          <div className='flex'>
            <Input
              type="text"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Search"
              className="p-3 w-64"
              style={{
                background: "transparent",
                color: isDark ? "black" : "white",
                borderRadius: "6px",
              }}
            />
          </div>
        </div>

        {dataTotalLength !== null && dataTotalLength === 0 && (
          <h3 className="d-flex justify-content-center align-items-center w-100 mt-5">
            No post found!
          </h3>
        )}

        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          style={{ overflow: "visible" }}
          // loader={
          //   <div className='d-flex justify-content-center p-3'>
          //     {isDark
          //       ? <img className='loading-logo' src={`${IMAGE_URL}/images/short_logo_dark.svg`} alt="Loading" />
          //       : <img className='loading-logo' src={`${IMAGE_URL}/images/short_logo_white.svg`} alt="Loading" />
          //     }
          //   </div>
          // }
          scrollableTarget="scrollableDiv"
        >
          <div className="masonry-grid -ml-2">
            {filterItem.length > 0
              ? filterItem.map((item, i) => (
                <div className=" masonry-grid-item" key={i} style={{ paddingTop: '1px' }}>
                  <PostCard
                    des={item.postContent}
                    image={item.originalUrl}
                    postype={item.postType === 'PDF' || item.postId?.startsWith('carousel_') ? 'CAROUSEL' : item.postType}
                    data={item}
                    setData={setData}
                    isEvergreen={true}
                    isBookmark={true}
                  />
                </div>
              ))
              : searchItem && (
                <div
                  className={`flex flex-col gap-4 ${layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"
                    }`}
                >
                  <h3 className="no-posts text-center my-5">No posts found!</h3>
                </div>
              )}
            {!searchItem &&
              data.map((item, i) => (
                <div className=" masonry-grid-item" key={i} style={{ paddingTop: '1px' }}>
                  <PostCard
                    des={item.postContent}
                    image={item.originalUrl}
                    postype={item.postType === 'PDF' || item.postId?.startsWith('carousel_') ? 'CAROUSEL' : item.postType}
                    data={item}
                    setData={setData}
                    isEvergreen={true}
                    isBookmark={true}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default History;
